.createdBackground{
    background-color: #fff0e9;
    height: 100vh;
    display: grid;
    place-items: center;
  }
  
  .notificationAccountDiv{
    display: grid;
    place-items: center;
  }
  
  .notificationAccountDiv p {
    font-size: 25px;
    text-align: center;
    color: black
  }
  