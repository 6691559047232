textarea {
  width: 98%;
  height: 33vh;
  resize: none;
}

form {
  width: 90%;
}

.ck.ck-button .ck.ck-tooltip {
  display: none;
}

.messagecompose {
  flex-direction: column !important;
}
