*{
    overflow-x: hidden;
}

.content-div {
    height: 1080px;
    font-family: 'Antonio', sans-serif;
    color: white;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    color: black;
    /* border: solid 2px green; */
}

.button-container {
    display: grid;
    grid-template-columns: 0.3fr 0.3fr;
    margin-left: 29%;
}


.leftSection p{
    font-size: 1.5rem;
    width: 50%;
    font-family: 'Open Sans', sans-serif;

    text-align: left;
}

.websiteDescription{
    font-size: 1.5rem;
    font-weight: 100;
    width: 50%;
    font-family: 'Open Sans', sans-serif;
    padding: none;
}

.rightSection p{
    font-size: 1.5rem;
    width: 50%;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
}

.section a {
    display: inline-block;
    transition: all ease 0.3s;
    font-family: 'Open Sans', sans-serif;
    color: #FF2626;
    font-size: 1vw;
    font-weight: 800;
    text-decoration: none;
}

.section a::after{
    content: " >";
}


.floatingV2{
    position: relative;
    animation: floatingV2 3s ease-in-out infinite;
    animation-delay: 0s;
}

.floatingV3{
    position: relative;
    animation: floatingV3 3s ease-in-out infinite;
    animation-delay: 2s;
}

.coloredStripe1{
    /* background-color: #ffd6c2; */
    background-color: #EEEEEE;
    /* background-color: #78B6D1; */
}

.coloredStripe2{
    background-color: #ffdeab;
}


@keyframes floatingV2 {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, 15%);
    }
    100% {
        transform: translate(0, 0px);
    }
}



.images{
    position: relative;
    width: 50%;
    padding: 15rem;
}

.rightSection{
    position: relative;
    width: 50%;
    /* border: solid 1px red; */
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

.leftSection{
    position: relative;
    width: 50%;
    /* border: solid 1px red; */
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    
}

.viewHereContainer{
    width: 50%;
    display: flex;
    justify-content: left;
}

.section{
    height: 100%;
    display: flex;
    justify-content:space-evenly;
    clear: both;
    opacity: 1;
    transition: opacity 2s ease;
}

.titleBox{
    color: black;
    width: 50%;
    display: flex;
    align-items: left;
    font-size: 2rem;
}

.titleBox h2{
    font-size: 2rem;
}

.textBorder {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.homeDiv {
    color: white;
}


.buttonClass {
    transition: all ease 0.5s;
    border-radius: 50px;
    outline: none;
    text-decoration: none;
    border: none;
    background-color: #003887;
    color: white;
    font-size: 16px;
}

.buttonClass:hover {
    transition: all ease 0.5s;
    background-color: #134c9c;
    cursor: pointer;
    color: rgb(255, 255, 255);
}

.hidden{
    opacity: 0;
}

.leftSection a{
    font-size: 1.2rem;
}

.rightSection a{
    font-size: 1.2rem;
}



@media (max-width: 900px){

    .section{
        display: grid;
        place-items: center;
        overflow: hidden;
    }

    .rightSection{
        width: 100%;
        height: 100%;
        overflow: hidden;
        right: 0;
    }

    .leftSection{
        width: 100%;
        height: 100%;
        overflow: hidden;
        left: 0;
    }

    .images{
        position: relative;
        padding: 10%;
        top: 0;
    }
    .rightSection a{
        padding-bottom: 5%;
    }

}