.ForgotcreatedBackground{
  background-color: #fff0e9;
  height: 100vh;
  display: grid;
  place-items: center;
}

.ForgotForgotPassowrdDiv{
  height: 30%;
  width: 50rem;
  display: grid;
  place-items: center;
  border-radius: 15px;
  padding: 5%;
}

#submitBtn{
  padding: 5%;
}

.ForgotnotificationAccountDiv p {
  font-size: 25px;
  text-align: center;
  color: white;
}

