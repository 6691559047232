.error {
    text-align: center;
    height: 100%;
    margin: 0;
  }
  
  .move{
    margin-top: 20%;
  }

  .background404{
    height: 90vh;
    background-color: #fff0e9;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: grid;
    place-items: center;
  }

  .notFound h1{
    font-size: 4vw;
  }
  
  .notFound h2{
    font-size: 3vw;
  }
  

  .notFoundText{
    text-align: center;
  }