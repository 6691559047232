.innerStatementContent{
    text-align: center;
    display: grid;
    place-items: center;
    height: 100%;
    background-color: white;
}

.titleBoxStatement{
    border-radius: 4px;
    background-color: #ffb63f ; 
    width: 20%;
    height: 7rem;
}

.titleStatementText{
    text-align: center;
    color: #EEEEEE;
    display: block;
}

@media (max-width: 1000px) {
    .titleBoxStatement{

        background-color: #ffb63f; 
        width: 90%;
    }
}
#privacypolicy{
    margin-top: 2rem;
    display: block;

}