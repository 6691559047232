.cookieStatementText{
    text-align: center;
    background-color: #ffffff;
    max-width: 468pt;
    padding: 10pt 72pt 72pt 72pt;
    font-weight: lighter;
}

.cookieStatementText h1{
    font-weight: 100;
    font-family: "Times New Roman";
    font-size: 12pt;
}