.InputBox {
  font-family: "Oswald", sans-serif;
  background-color: transparent;
  display: grid;
  /* grid-template-columns: 0.8fr; */
  width: 100%;
  margin-left: 0;
  text-align: center;
}

.InputLine {
  transition: all ease 0.3s;
  font-family: "Oswald", sans-serif;
  width: 100%;
  margin-top: 5%;
  outline: none;
  font-size: 2vw;
  background-color: transparent;
  color: black;
  border-bottom: 5px solid #ff653f;
  border-top: none;
  border-left: none;
  border-right: none;
}

.InputLine:hover {
  transition: all ease 0.3s;
  border-bottom: 5px solid #e6856a;
}

.LabelText {
  text-align: left;
  position: relative;
  bottom: -10%;
  left: 0%;
  margin-bottom: -2%;
  font-size: 3vmin;
  font-family: "Ubuntu", sans-serif;
}

.invalid {
  color: rgb(209, 101, 39);
  z-index: 100;
  font-size: 2vmin;
}

.centerCheck {
  display: flex;
  justify-content: left;
  overflow: hidden;
}

@media (max-width: 800px) {
  .InputLine {
    font-size: 3vh;
  }
}

@media (max-width: 425px) {
  .LabelText {
    font-size: 5vw;
  }
}
