.yellowBtn{
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background-color: #ffb63f;
    color: white;
    text-decoration: none;
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
    outline: none;
    width: 315px;
    height: 56px;
    border: none;
    border-radius: 15px;
}
.yellowBtn:hover{
    box-shadow: 5px 5px 1px rgba(41, 40, 40, 0.5);
    background-color: #ff653f;
    color:white;
    transition: all 0.3s ease-in-out;
}

@media (max-width:800px) {
    .yellowBtn{
        font-size: 18px;
    }
}