ul.lst-kix_30e0x9xoe2jl-0 {
    list-style-type: none
}

ul.lst-kix_30e0x9xoe2jl-1 {
    list-style-type: none
}

.lst-kix_30e0x9xoe2jl-1>li:before {
    content: "-  "
}

.lst-kix_30e0x9xoe2jl-2>li:before {
    content: "-  "
}

.lst-kix_30e0x9xoe2jl-4>li:before {
    content: "-  "
}

ul.lst-kix_30e0x9xoe2jl-8 {
    list-style-type: none
}

.lst-kix_30e0x9xoe2jl-3>li:before {
    content: "-  "
}

.lst-kix_30e0x9xoe2jl-5>li:before {
    content: "-  "
}

ul.lst-kix_30e0x9xoe2jl-6 {
    list-style-type: none
}

.lst-kix_7yruouvsogai-8>li:before {
    content: "\0025a0  "
}

ul.lst-kix_30e0x9xoe2jl-7 {
    list-style-type: none
}

ul.lst-kix_30e0x9xoe2jl-4 {
    list-style-type: none
}

ul.lst-kix_30e0x9xoe2jl-5 {
    list-style-type: none
}

ul.lst-kix_30e0x9xoe2jl-2 {
    list-style-type: none
}

ul.lst-kix_30e0x9xoe2jl-3 {
    list-style-type: none
}

ul.lst-kix_7yruouvsogai-8 {
    list-style-type: none
}

ul.lst-kix_7yruouvsogai-7 {
    list-style-type: none
}

ul.lst-kix_7yruouvsogai-6 {
    list-style-type: none
}

ul.lst-kix_7yruouvsogai-5 {
    list-style-type: none
}

ul.lst-kix_7yruouvsogai-4 {
    list-style-type: none
}

ul.lst-kix_7yruouvsogai-3 {
    list-style-type: none
}

ul.lst-kix_7yruouvsogai-2 {
    list-style-type: none
}

ul.lst-kix_7yruouvsogai-1 {
    list-style-type: none
}

ul.lst-kix_7yruouvsogai-0 {
    list-style-type: none
}

.lst-kix_30e0x9xoe2jl-0>li:before {
    content: "-  "
}

.lst-kix_tyklhlhu1hjj-1>li:before {
    content: "-  "
}

.lst-kix_tyklhlhu1hjj-0>li:before {
    content: "-  "
}

.lst-kix_tyklhlhu1hjj-2>li:before {
    content: "-  "
}

.lst-kix_tyklhlhu1hjj-3>li:before {
    content: "-  "
}

.lst-kix_tyklhlhu1hjj-4>li:before {
    content: "-  "
}

.lst-kix_tyklhlhu1hjj-5>li:before {
    content: "-  "
}

.lst-kix_tyklhlhu1hjj-8>li:before {
    content: "-  "
}

.lst-kix_tyklhlhu1hjj-6>li:before {
    content: "-  "
}

.lst-kix_tyklhlhu1hjj-7>li:before {
    content: "-  "
}

.lst-kix_30e0x9xoe2jl-8>li:before {
    content: "-  "
}

.lst-kix_30e0x9xoe2jl-7>li:before {
    content: "-  "
}

.lst-kix_30e0x9xoe2jl-6>li:before {
    content: "-  "
}

.lst-kix_7yruouvsogai-7>li:before {
    content: "\0025cb  "
}

ul.lst-kix_tyklhlhu1hjj-5 {
    list-style-type: none
}

ul.lst-kix_tyklhlhu1hjj-6 {
    list-style-type: none
}

ul.lst-kix_tyklhlhu1hjj-3 {
    list-style-type: none
}

.lst-kix_7yruouvsogai-6>li:before {
    content: "\0025cf  "
}

ul.lst-kix_tyklhlhu1hjj-4 {
    list-style-type: none
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

ul.lst-kix_tyklhlhu1hjj-7 {
    list-style-type: none
}

.lst-kix_7yruouvsogai-5>li:before {
    content: "\0025a0  "
}

ul.lst-kix_tyklhlhu1hjj-8 {
    list-style-type: none
}

.lst-kix_7yruouvsogai-2>li:before {
    content: "\0025a0  "
}

.lst-kix_7yruouvsogai-4>li:before {
    content: "\0025cb  "
}

ul.lst-kix_tyklhlhu1hjj-1 {
    list-style-type: none
}

ul.lst-kix_tyklhlhu1hjj-2 {
    list-style-type: none
}

.lst-kix_7yruouvsogai-3>li:before {
    content: "\0025cf  "
}

ul.lst-kix_tyklhlhu1hjj-0 {
    list-style-type: none
}

.lst-kix_7yruouvsogai-0>li:before {
    content: "\0025cf  "
}

.lst-kix_7yruouvsogai-1>li:before {
    content: "\0025cb  "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.pc0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.pc7 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Arial";
    font-style: normal
}

.pc1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    overflow-y: hidden;
}

.pc8 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline
}

.pc5 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
}

.pc11 {
    color: inherit;
    text-decoration: inherit
}

.pc3 {
    padding: 0;
    margin: 0
}

.pc10 {
    font-size: 11pt;
    color: #000000
}

.pc2 {
    margin-left: 36pt;
    padding-left: 0pt
}

.pc6 {
    margin-left: 36pt
}

.pc4 {
    height: 11pt
}

.pc9 {
    font-weight: 700
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}