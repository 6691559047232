.AccountcreatedBackground {
  background-color: #fff0e9;
  height: 100vh;
}

.accountCreatedBox {
  display: flex;
  flex-direction: column;
  height: 30vh;
  width: 40vw;
  margin: auto;
  margin-top: 20vh;
  background-color: white;
  outline: 8px solid #ffb63f;
  outline-width: 5px;
  border-radius: 2rem;
}

.AccountnotificationAccountDiv {
  height: 30%;
  border-radius: 15px;
  margin: auto;
}

.AccountnotificationAccountDiv p {
  font-size: 25px;
  text-align: center;
  color: black;
}

.image {
  width: 5vw;
  margin: auto;
  bottom: 0px;
}

@media (max-width: 1200px) {
  .accountCreatedBox {
    height: 40vh;
  }
  .image {
    width: 10vw;
  }
}

@media (max-width: 425px) {
  .accountCreatedBox {
    width: 80vw;
  }
  .image {
    width: 10vh;
  }
}
