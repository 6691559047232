.lst-kix_qf8cunf9lmoq-8>li:before {
    content: "-  "
}

.lst-kix_qf8cunf9lmoq-0>li:before {
    content: "-  "
}

.lst-kix_965ch4yh5iho-0>li:before {
    content: "-  "
}

.lst-kix_965ch4yh5iho-1>li:before {
    content: "-  "
}

.lst-kix_965ch4yh5iho-3>li:before {
    content: "-  "
}

.lst-kix_965ch4yh5iho-2>li:before {
    content: "-  "
}

.lst-kix_965ch4yh5iho-4>li:before {
    content: "-  "
}

.lst-kix_dhzuje2qhdxt-1>li:before {
    content: "-  "
}

.lst-kix_dhzuje2qhdxt-3>li:before {
    content: "-  "
}

.lst-kix_dhzuje2qhdxt-2>li:before {
    content: "-  "
}

.lst-kix_dhzuje2qhdxt-5>li:before {
    content: "-  "
}

.lst-kix_dhzuje2qhdxt-4>li:before {
    content: "-  "
}

.lst-kix_965ch4yh5iho-7>li:before {
    content: "-  "
}

.lst-kix_965ch4yh5iho-6>li:before {
    content: "-  "
}

.lst-kix_965ch4yh5iho-8>li:before {
    content: "-  "
}

.lst-kix_965ch4yh5iho-5>li:before {
    content: "-  "
}

.lst-kix_dhzuje2qhdxt-6>li:before {
    content: "-  "
}

.lst-kix_dhzuje2qhdxt-7>li:before {
    content: "-  "
}

.lst-kix_dhzuje2qhdxt-8>li:before {
    content: "-  "
}

ul.lst-kix_qcycb3pl8tkj-0 {
    list-style-type: none
}

ul.lst-kix_qcycb3pl8tkj-3 {
    list-style-type: none
}

ul.lst-kix_qcycb3pl8tkj-4 {
    list-style-type: none
}

ul.lst-kix_qcycb3pl8tkj-1 {
    list-style-type: none
}

ul.lst-kix_qcycb3pl8tkj-2 {
    list-style-type: none
}

ul.lst-kix_qcycb3pl8tkj-7 {
    list-style-type: none
}

ul.lst-kix_qf8cunf9lmoq-5 {
    list-style-type: none
}

ul.lst-kix_qcycb3pl8tkj-8 {
    list-style-type: none
}

ul.lst-kix_qf8cunf9lmoq-4 {
    list-style-type: none
}

ul.lst-kix_qcycb3pl8tkj-5 {
    list-style-type: none
}

ul.lst-kix_qf8cunf9lmoq-3 {
    list-style-type: none
}

ul.lst-kix_qcycb3pl8tkj-6 {
    list-style-type: none
}

ul.lst-kix_qf8cunf9lmoq-2 {
    list-style-type: none
}

.lst-kix_qf8cunf9lmoq-1>li:before {
    content: "-  "
}

ul.lst-kix_qf8cunf9lmoq-8 {
    list-style-type: none
}

ul.lst-kix_qf8cunf9lmoq-7 {
    list-style-type: none
}

ul.lst-kix_qf8cunf9lmoq-6 {
    list-style-type: none
}

.lst-kix_qf8cunf9lmoq-2>li:before {
    content: "-  "
}

.lst-kix_qf8cunf9lmoq-3>li:before {
    content: "-  "
}

ul.lst-kix_qf8cunf9lmoq-1 {
    list-style-type: none
}

ul.lst-kix_qf8cunf9lmoq-0 {
    list-style-type: none
}

.lst-kix_qf8cunf9lmoq-4>li:before {
    content: "-  "
}

.lst-kix_qf8cunf9lmoq-6>li:before {
    content: "-  "
}

.lst-kix_qf8cunf9lmoq-7>li:before {
    content: "-  "
}

.lst-kix_qf8cunf9lmoq-5>li:before {
    content: "-  "
}

.lst-kix_dhzuje2qhdxt-0>li:before {
    content: "-  "
}

.lst-kix_oywbd7f7m72b-8>li:before {
    content: "-  "
}

.lst-kix_5j0xkdvqwklg-8>li:before {
    content: "-  "
}

ul.lst-kix_dhzuje2qhdxt-1 {
    list-style-type: none
}

.lst-kix_pbwochf5nx0w-1>li:before {
    content: "-  "
}

ul.lst-kix_dhzuje2qhdxt-0 {
    list-style-type: none
}

.lst-kix_5j0xkdvqwklg-2>li:before {
    content: "-  "
}

.lst-kix_5j0xkdvqwklg-4>li:before {
    content: "-  "
}

ul.lst-kix_dhzuje2qhdxt-3 {
    list-style-type: none
}

ul.lst-kix_dhzuje2qhdxt-2 {
    list-style-type: none
}

ul.lst-kix_dhzuje2qhdxt-5 {
    list-style-type: none
}

.lst-kix_pbwochf5nx0w-3>li:before {
    content: "-  "
}

ul.lst-kix_dhzuje2qhdxt-4 {
    list-style-type: none
}

ul.lst-kix_dhzuje2qhdxt-7 {
    list-style-type: none
}

ul.lst-kix_dhzuje2qhdxt-6 {
    list-style-type: none
}

.lst-kix_pbwochf5nx0w-5>li:before {
    content: "-  "
}

ul.lst-kix_dhzuje2qhdxt-8 {
    list-style-type: none
}

.lst-kix_5j0xkdvqwklg-6>li:before {
    content: "-  "
}

.lst-kix_b4zbfpj40z8j-1>li:before {
    content: "-  "
}

.lst-kix_b4zbfpj40z8j-3>li:before {
    content: "-  "
}

.lst-kix_pbwochf5nx0w-7>li:before {
    content: "-  "
}

.lst-kix_oywbd7f7m72b-0>li:before {
    content: "-  "
}

.lst-kix_5j0xkdvqwklg-0>li:before {
    content: "-  "
}

.lst-kix_oywbd7f7m72b-4>li:before {
    content: "-  "
}

ul.lst-kix_oywbd7f7m72b-8 {
    list-style-type: none
}

ul.lst-kix_oywbd7f7m72b-7 {
    list-style-type: none
}

ul.lst-kix_oywbd7f7m72b-6 {
    list-style-type: none
}

.lst-kix_oywbd7f7m72b-2>li:before {
    content: "-  "
}

.lst-kix_oywbd7f7m72b-6>li:before {
    content: "-  "
}

ul.lst-kix_oywbd7f7m72b-1 {
    list-style-type: none
}

ul.lst-kix_oywbd7f7m72b-0 {
    list-style-type: none
}

ul.lst-kix_oywbd7f7m72b-5 {
    list-style-type: none
}

ul.lst-kix_oywbd7f7m72b-4 {
    list-style-type: none
}

ul.lst-kix_oywbd7f7m72b-3 {
    list-style-type: none
}

ul.lst-kix_oywbd7f7m72b-2 {
    list-style-type: none
}

.lst-kix_d862swcpqqg6-1>li:before {
    content: "-  "
}

ul.lst-kix_pbwochf5nx0w-0 {
    list-style-type: none
}

ul.lst-kix_pbwochf5nx0w-1 {
    list-style-type: none
}

.lst-kix_d862swcpqqg6-3>li:before {
    content: "-  "
}

ul.lst-kix_5j0xkdvqwklg-7 {
    list-style-type: none
}

ul.lst-kix_5j0xkdvqwklg-8 {
    list-style-type: none
}

.lst-kix_d862swcpqqg6-5>li:before {
    content: "-  "
}

ul.lst-kix_5j0xkdvqwklg-5 {
    list-style-type: none
}

.lst-kix_jz31kkm24p0b-0>li:before {
    content: "-  "
}

ul.lst-kix_5j0xkdvqwklg-6 {
    list-style-type: none
}

ul.lst-kix_5j0xkdvqwklg-3 {
    list-style-type: none
}

ul.lst-kix_5j0xkdvqwklg-4 {
    list-style-type: none
}

ul.lst-kix_5j0xkdvqwklg-1 {
    list-style-type: none
}

ul.lst-kix_5j0xkdvqwklg-2 {
    list-style-type: none
}

.lst-kix_d862swcpqqg6-7>li:before {
    content: "-  "
}

ul.lst-kix_pbwochf5nx0w-6 {
    list-style-type: none
}

ul.lst-kix_pbwochf5nx0w-7 {
    list-style-type: none
}

ul.lst-kix_pbwochf5nx0w-8 {
    list-style-type: none
}

.lst-kix_b4zbfpj40z8j-5>li:before {
    content: "-  "
}

ul.lst-kix_pbwochf5nx0w-2 {
    list-style-type: none
}

ul.lst-kix_pbwochf5nx0w-3 {
    list-style-type: none
}

ul.lst-kix_pbwochf5nx0w-4 {
    list-style-type: none
}

ul.lst-kix_pbwochf5nx0w-5 {
    list-style-type: none
}

.lst-kix_jz31kkm24p0b-8>li:before {
    content: "-  "
}

.lst-kix_b4zbfpj40z8j-7>li:before {
    content: "-  "
}

ul.lst-kix_5j0xkdvqwklg-0 {
    list-style-type: none
}

.lst-kix_jz31kkm24p0b-2>li:before {
    content: "-  "
}

.lst-kix_jz31kkm24p0b-4>li:before {
    content: "-  "
}

.lst-kix_jz31kkm24p0b-6>li:before {
    content: "-  "
}

ul.lst-kix_b4zbfpj40z8j-6 {
    list-style-type: none
}

ul.lst-kix_b4zbfpj40z8j-7 {
    list-style-type: none
}

ul.lst-kix_b4zbfpj40z8j-4 {
    list-style-type: none
}

ul.lst-kix_b4zbfpj40z8j-5 {
    list-style-type: none
}

ul.lst-kix_b4zbfpj40z8j-2 {
    list-style-type: none
}

ul.lst-kix_b4zbfpj40z8j-3 {
    list-style-type: none
}

ul.lst-kix_b4zbfpj40z8j-0 {
    list-style-type: none
}

ul.lst-kix_b4zbfpj40z8j-1 {
    list-style-type: none
}

.lst-kix_6iz0wx62dx5h-3>li:before {
    content: "-  "
}

.lst-kix_6iz0wx62dx5h-4>li:before {
    content: "-  "
}

.lst-kix_qcycb3pl8tkj-8>li:before {
    content: "-  "
}

ul.lst-kix_b4zbfpj40z8j-8 {
    list-style-type: none
}

.lst-kix_6iz0wx62dx5h-6>li:before {
    content: "-  "
}

.lst-kix_qcycb3pl8tkj-5>li:before {
    content: "-  "
}

.lst-kix_qcycb3pl8tkj-6>li:before {
    content: "-  "
}

.lst-kix_6iz0wx62dx5h-5>li:before {
    content: "-  "
}

.lst-kix_6iz0wx62dx5h-7>li:before {
    content: "-  "
}

.lst-kix_qcycb3pl8tkj-7>li:before {
    content: "-  "
}

.lst-kix_qcycb3pl8tkj-1>li:before {
    content: "-  "
}

.lst-kix_qcycb3pl8tkj-2>li:before {
    content: "-  "
}

.lst-kix_qcycb3pl8tkj-0>li:before {
    content: "-  "
}

.lst-kix_qcycb3pl8tkj-4>li:before {
    content: "-  "
}

.lst-kix_qcycb3pl8tkj-3>li:before {
    content: "-  "
}

.lst-kix_6iz0wx62dx5h-2>li:before {
    content: "-  "
}

.lst-kix_6iz0wx62dx5h-1>li:before {
    content: "-  "
}

.lst-kix_6iz0wx62dx5h-0>li:before {
    content: "-  "
}

ul.lst-kix_965ch4yh5iho-8 {
    list-style-type: none
}

.lst-kix_r9i76zj94d0x-7>li:before {
    content: "-  "
}

.lst-kix_r9i76zj94d0x-6>li:before {
    content: "-  "
}

ul.lst-kix_965ch4yh5iho-2 {
    list-style-type: none
}

ul.lst-kix_965ch4yh5iho-3 {
    list-style-type: none
}

ul.lst-kix_965ch4yh5iho-0 {
    list-style-type: none
}

ul.lst-kix_965ch4yh5iho-1 {
    list-style-type: none
}

ul.lst-kix_965ch4yh5iho-6 {
    list-style-type: none
}

ul.lst-kix_965ch4yh5iho-7 {
    list-style-type: none
}

ul.lst-kix_965ch4yh5iho-4 {
    list-style-type: none
}

.lst-kix_r9i76zj94d0x-8>li:before {
    content: "-  "
}

ul.lst-kix_965ch4yh5iho-5 {
    list-style-type: none
}

.lst-kix_r9i76zj94d0x-1>li:before {
    content: "-  "
}

.lst-kix_6iz0wx62dx5h-8>li:before {
    content: "-  "
}

.lst-kix_r9i76zj94d0x-3>li:before {
    content: "-  "
}

.lst-kix_r9i76zj94d0x-2>li:before {
    content: "-  "
}

.lst-kix_r9i76zj94d0x-5>li:before {
    content: "-  "
}

.lst-kix_r9i76zj94d0x-4>li:before {
    content: "-  "
}

.lst-kix_r9i76zj94d0x-0>li:before {
    content: "-  "
}

ul.lst-kix_6iz0wx62dx5h-4 {
    list-style-type: none
}

ul.lst-kix_6iz0wx62dx5h-5 {
    list-style-type: none
}

ul.lst-kix_6iz0wx62dx5h-6 {
    list-style-type: none
}

.lst-kix_oywbd7f7m72b-7>li:before {
    content: "-  "
}

ul.lst-kix_6iz0wx62dx5h-7 {
    list-style-type: none
}

ul.lst-kix_6iz0wx62dx5h-8 {
    list-style-type: none
}

ul.lst-kix_jz31kkm24p0b-3 {
    list-style-type: none
}

.lst-kix_5j0xkdvqwklg-3>li:before {
    content: "-  "
}

ul.lst-kix_jz31kkm24p0b-2 {
    list-style-type: none
}

ul.lst-kix_jz31kkm24p0b-1 {
    list-style-type: none
}

ul.lst-kix_jz31kkm24p0b-0 {
    list-style-type: none
}

ul.lst-kix_6iz0wx62dx5h-0 {
    list-style-type: none
}

ul.lst-kix_jz31kkm24p0b-7 {
    list-style-type: none
}

ul.lst-kix_6iz0wx62dx5h-1 {
    list-style-type: none
}

ul.lst-kix_jz31kkm24p0b-6 {
    list-style-type: none
}

.lst-kix_pbwochf5nx0w-2>li:before {
    content: "-  "
}

ul.lst-kix_6iz0wx62dx5h-2 {
    list-style-type: none
}

ul.lst-kix_jz31kkm24p0b-5 {
    list-style-type: none
}

ul.lst-kix_6iz0wx62dx5h-3 {
    list-style-type: none
}

ul.lst-kix_jz31kkm24p0b-4 {
    list-style-type: none
}

.lst-kix_5j0xkdvqwklg-7>li:before {
    content: "-  "
}

.lst-kix_pbwochf5nx0w-4>li:before {
    content: "-  "
}

ul.lst-kix_jz31kkm24p0b-8 {
    list-style-type: none
}

.lst-kix_5j0xkdvqwklg-5>li:before {
    content: "-  "
}

.lst-kix_pbwochf5nx0w-8>li:before {
    content: "-  "
}

.lst-kix_pbwochf5nx0w-6>li:before {
    content: "-  "
}

.lst-kix_b4zbfpj40z8j-2>li:before {
    content: "-  "
}

.lst-kix_oywbd7f7m72b-1>li:before {
    content: "-  "
}

.lst-kix_5j0xkdvqwklg-1>li:before {
    content: "-  "
}

.lst-kix_b4zbfpj40z8j-0>li:before {
    content: "-  "
}

.lst-kix_oywbd7f7m72b-3>li:before {
    content: "-  "
}

.lst-kix_oywbd7f7m72b-5>li:before {
    content: "-  "
}

ul.lst-kix_r9i76zj94d0x-0 {
    list-style-type: none
}

.lst-kix_d862swcpqqg6-2>li:before {
    content: "-  "
}

ul.lst-kix_d862swcpqqg6-0 {
    list-style-type: none
}

.lst-kix_d862swcpqqg6-6>li:before {
    content: "-  "
}

.lst-kix_d862swcpqqg6-4>li:before {
    content: "-  "
}

.lst-kix_jz31kkm24p0b-1>li:before {
    content: "-  "
}

ul.lst-kix_d862swcpqqg6-8 {
    list-style-type: none
}

ul.lst-kix_d862swcpqqg6-7 {
    list-style-type: none
}

ul.lst-kix_d862swcpqqg6-6 {
    list-style-type: none
}

ul.lst-kix_d862swcpqqg6-5 {
    list-style-type: none
}

ul.lst-kix_d862swcpqqg6-4 {
    list-style-type: none
}

ul.lst-kix_d862swcpqqg6-3 {
    list-style-type: none
}

ul.lst-kix_d862swcpqqg6-2 {
    list-style-type: none
}

.lst-kix_b4zbfpj40z8j-6>li:before {
    content: "-  "
}

ul.lst-kix_d862swcpqqg6-1 {
    list-style-type: none
}

.lst-kix_d862swcpqqg6-8>li:before {
    content: "-  "
}

.lst-kix_b4zbfpj40z8j-4>li:before {
    content: "-  "
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_b4zbfpj40z8j-8>li:before {
    content: "-  "
}

.lst-kix_pbwochf5nx0w-0>li:before {
    content: "-  "
}

.lst-kix_jz31kkm24p0b-3>li:before {
    content: "-  "
}

.lst-kix_jz31kkm24p0b-7>li:before {
    content: "-  "
}

ul.lst-kix_r9i76zj94d0x-8 {
    list-style-type: none
}

ul.lst-kix_r9i76zj94d0x-7 {
    list-style-type: none
}

ul.lst-kix_r9i76zj94d0x-6 {
    list-style-type: none
}

ul.lst-kix_r9i76zj94d0x-5 {
    list-style-type: none
}

ul.lst-kix_r9i76zj94d0x-4 {
    list-style-type: none
}

ul.lst-kix_r9i76zj94d0x-3 {
    list-style-type: none
}

.lst-kix_d862swcpqqg6-0>li:before {
    content: "-  "
}

ul.lst-kix_r9i76zj94d0x-2 {
    list-style-type: none
}

.lst-kix_jz31kkm24p0b-5>li:before {
    content: "-  "
}

ul.lst-kix_r9i76zj94d0x-1 {
    list-style-type: none
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.b0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.b9 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.b4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: center
}

.b1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.b5 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 400
}

.b8 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
}

.b6 {
    padding: 0;
    margin: 0
}

.b3 {
    margin-left: 36pt
}

.b2 {
    height: 11pt
}

.b10 {
    margin-left: 72pt
}

.b7 {
    padding-left: 0pt
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}