*{
    -webkit-user-drag: none;
    user-select: text;
}


.note{
    background-color: #EEEEEE;
    font-family: 'Oswald', sans-serif;
    font-weight:bold;
    color:white;
    height: 100%;
    font-size: larger;
    text-align: center;
}

.footerOptions li {
    display: inline;
    padding: 2%;
}

.footerOptions{
    overflow-y: hidden;
}

.footerOptions li a{
    display: inline;
    padding: 2%;
    color: white;
    text-decoration: none;
    /* color: #ffb63f; */
    color: black;
}


.footerText{
    color: #ffb63f;
    font-size: 0.9vw;
    font-weight: 100;
    padding: 2% 0 2% 0;
}

@media (max-width:1000px){
    .footerText{
        font-size: 2vh;
        font-weight: 100;
    }
    
    .footerOptions li {
        display: block;
        padding: 2%;
        text-align: left;
    }
    
}