.lst-kix_38l448z239x5-0>li {
    counter-increment: lst-ctn-kix_38l448z239x5-0
}

ol.lst-kix_38l448z239x5-2.start {
    counter-reset: lst-ctn-kix_38l448z239x5-2 0
}

ul.lst-kix_p7ker46zha7z-3 {
    list-style-type: none
}

ul.lst-kix_p7ker46zha7z-2 {
    list-style-type: none
}

ul.lst-kix_p7ker46zha7z-5 {
    list-style-type: none
}

ul.lst-kix_p7ker46zha7z-4 {
    list-style-type: none
}

.lst-kix_2gh2eqf8mlmd-6>li {
    counter-increment: lst-ctn-kix_2gh2eqf8mlmd-6
}

ul.lst-kix_p7ker46zha7z-1 {
    list-style-type: none
}

ul.lst-kix_p7ker46zha7z-0 {
    list-style-type: none
}

ul.lst-kix_p7ker46zha7z-7 {
    list-style-type: none
}

ul.lst-kix_p7ker46zha7z-6 {
    list-style-type: none
}

ul.lst-kix_p7ker46zha7z-8 {
    list-style-type: none
}

ol.lst-kix_38l448z239x5-5.start {
    counter-reset: lst-ctn-kix_38l448z239x5-5 0
}

ol.lst-kix_38l448z239x5-8.start {
    counter-reset: lst-ctn-kix_38l448z239x5-8 0
}

.lst-kix_2gh2eqf8mlmd-5>li {
    counter-increment: lst-ctn-kix_2gh2eqf8mlmd-5
}

.lst-kix_2gh2eqf8mlmd-8>li {
    counter-increment: lst-ctn-kix_2gh2eqf8mlmd-8
}

ol.lst-kix_2gh2eqf8mlmd-6.start {
    counter-reset: lst-ctn-kix_2gh2eqf8mlmd-6 0
}

ol.lst-kix_38l448z239x5-4 {
    list-style-type: none
}

ol.lst-kix_38l448z239x5-3 {
    list-style-type: none
}

ol.lst-kix_38l448z239x5-6 {
    list-style-type: none
}

ol.lst-kix_38l448z239x5-5 {
    list-style-type: none
}

ol.lst-kix_38l448z239x5-8 {
    list-style-type: none
}

ol.lst-kix_38l448z239x5-7 {
    list-style-type: none
}

.lst-kix_2gh2eqf8mlmd-3>li {
    counter-increment: lst-ctn-kix_2gh2eqf8mlmd-3
}

ol.lst-kix_2gh2eqf8mlmd-3.start {
    counter-reset: lst-ctn-kix_2gh2eqf8mlmd-3 0
}

ol.lst-kix_38l448z239x5-0 {
    list-style-type: none
}

ol.lst-kix_38l448z239x5-2 {
    list-style-type: none
}

ol.lst-kix_38l448z239x5-1 {
    list-style-type: none
}

.lst-kix_2gh2eqf8mlmd-2>li {
    counter-increment: lst-ctn-kix_2gh2eqf8mlmd-2
}

ol.lst-kix_38l448z239x5-3.start {
    counter-reset: lst-ctn-kix_38l448z239x5-3 0
}

ol.lst-kix_2gh2eqf8mlmd-2 {
    list-style-type: none
}

.lst-kix_38l448z239x5-4>li {
    counter-increment: lst-ctn-kix_38l448z239x5-4
}

ol.lst-kix_2gh2eqf8mlmd-3 {
    list-style-type: none
}

ol.lst-kix_2gh2eqf8mlmd-0 {
    list-style-type: none
}

ol.lst-kix_2gh2eqf8mlmd-4.start {
    counter-reset: lst-ctn-kix_2gh2eqf8mlmd-4 0
}

.lst-kix_38l448z239x5-3>li:before {
    content: ""counter(lst-ctn-kix_38l448z239x5-3, decimal) ". "
}

ol.lst-kix_2gh2eqf8mlmd-1 {
    list-style-type: none
}

.lst-kix_38l448z239x5-1>li {
    counter-increment: lst-ctn-kix_38l448z239x5-1
}

.lst-kix_38l448z239x5-2>li:before {
    content: ""counter(lst-ctn-kix_38l448z239x5-2, lower-roman) ". "
}

ol.lst-kix_2gh2eqf8mlmd-8 {
    list-style-type: none
}

.lst-kix_38l448z239x5-1>li:before {
    content: ""counter(lst-ctn-kix_38l448z239x5-1, lower-latin) ". "
}

ol.lst-kix_2gh2eqf8mlmd-6 {
    list-style-type: none
}

ol.lst-kix_2gh2eqf8mlmd-7 {
    list-style-type: none
}

ol.lst-kix_2gh2eqf8mlmd-4 {
    list-style-type: none
}

.lst-kix_38l448z239x5-0>li:before {
    content: ""counter(lst-ctn-kix_38l448z239x5-0, decimal) ". "
}

ol.lst-kix_2gh2eqf8mlmd-5 {
    list-style-type: none
}

.lst-kix_38l448z239x5-7>li {
    counter-increment: lst-ctn-kix_38l448z239x5-7
}

ol.lst-kix_38l448z239x5-4.start {
    counter-reset: lst-ctn-kix_38l448z239x5-4 0
}

.lst-kix_p7ker46zha7z-6>li:before {
    content: "\0025cf  "
}

.lst-kix_2gh2eqf8mlmd-0>li {
    counter-increment: lst-ctn-kix_2gh2eqf8mlmd-0
}

.lst-kix_p7ker46zha7z-4>li:before {
    content: "\0025cb  "
}

.lst-kix_p7ker46zha7z-8>li:before {
    content: "\0025a0  "
}

.lst-kix_p7ker46zha7z-3>li:before {
    content: "\0025cf  "
}

.lst-kix_p7ker46zha7z-7>li:before {
    content: "\0025cb  "
}

.lst-kix_38l448z239x5-6>li {
    counter-increment: lst-ctn-kix_38l448z239x5-6
}

.lst-kix_p7ker46zha7z-5>li:before {
    content: "\0025a0  "
}

ol.lst-kix_2gh2eqf8mlmd-8.start {
    counter-reset: lst-ctn-kix_2gh2eqf8mlmd-8 0
}

ol.lst-kix_2gh2eqf8mlmd-5.start {
    counter-reset: lst-ctn-kix_2gh2eqf8mlmd-5 0
}

.lst-kix_p7ker46zha7z-0>li:before {
    content: "\0025cf  "
}

.lst-kix_38l448z239x5-4>li:before {
    content: ""counter(lst-ctn-kix_38l448z239x5-4, lower-latin) ". "
}

.lst-kix_p7ker46zha7z-2>li:before {
    content: "\0025a0  "
}

.lst-kix_wyr8muo8tj56-0>li:before {
    content: "-  "
}

.lst-kix_wyr8muo8tj56-1>li:before {
    content: "-  "
}

.lst-kix_38l448z239x5-5>li:before {
    content: ""counter(lst-ctn-kix_38l448z239x5-5, lower-roman) ". "
}

.lst-kix_wyr8muo8tj56-2>li:before {
    content: "-  "
}

.lst-kix_38l448z239x5-5>li {
    counter-increment: lst-ctn-kix_38l448z239x5-5
}

.lst-kix_p7ker46zha7z-1>li:before {
    content: "\0025cb  "
}

.lst-kix_38l448z239x5-6>li:before {
    content: ""counter(lst-ctn-kix_38l448z239x5-6, decimal) ". "
}

.lst-kix_s0j8qbu98r9n-4>li:before {
    content: "\0025cb  "
}

ol.lst-kix_2gh2eqf8mlmd-2.start {
    counter-reset: lst-ctn-kix_2gh2eqf8mlmd-2 0
}

.lst-kix_wyr8muo8tj56-4>li:before {
    content: "-  "
}

ul.lst-kix_wyr8muo8tj56-6 {
    list-style-type: none
}

ul.lst-kix_wyr8muo8tj56-5 {
    list-style-type: none
}

.lst-kix_s0j8qbu98r9n-3>li:before {
    content: "\0025cf  "
}

.lst-kix_s0j8qbu98r9n-5>li:before {
    content: "\0025a0  "
}

.lst-kix_wyr8muo8tj56-3>li:before {
    content: "-  "
}

.lst-kix_wyr8muo8tj56-5>li:before {
    content: "-  "
}

ul.lst-kix_wyr8muo8tj56-8 {
    list-style-type: none
}

.lst-kix_38l448z239x5-7>li:before {
    content: ""counter(lst-ctn-kix_38l448z239x5-7, lower-latin) ". "
}

ul.lst-kix_wyr8muo8tj56-7 {
    list-style-type: none
}

ul.lst-kix_wyr8muo8tj56-2 {
    list-style-type: none
}

ul.lst-kix_wyr8muo8tj56-1 {
    list-style-type: none
}

ul.lst-kix_wyr8muo8tj56-4 {
    list-style-type: none
}

.lst-kix_38l448z239x5-8>li:before {
    content: ""counter(lst-ctn-kix_38l448z239x5-8, lower-roman) ". "
}

ul.lst-kix_wyr8muo8tj56-3 {
    list-style-type: none
}

.lst-kix_s0j8qbu98r9n-8>li:before {
    content: "\0025a0  "
}

.lst-kix_s0j8qbu98r9n-7>li:before {
    content: "\0025cb  "
}

.lst-kix_wyr8muo8tj56-7>li:before {
    content: "-  "
}

ol.lst-kix_38l448z239x5-6.start {
    counter-reset: lst-ctn-kix_38l448z239x5-6 0
}

.lst-kix_s0j8qbu98r9n-6>li:before {
    content: "\0025cf  "
}

.lst-kix_wyr8muo8tj56-6>li:before {
    content: "-  "
}

.lst-kix_wyr8muo8tj56-8>li:before {
    content: "-  "
}

ul.lst-kix_wyr8muo8tj56-0 {
    list-style-type: none
}

.lst-kix_2gh2eqf8mlmd-8>li:before {
    content: ""counter(lst-ctn-kix_2gh2eqf8mlmd-8, lower-roman) ". "
}

.lst-kix_38l448z239x5-3>li {
    counter-increment: lst-ctn-kix_38l448z239x5-3
}

ul.lst-kix_s0j8qbu98r9n-0 {
    list-style-type: none
}

ul.lst-kix_s0j8qbu98r9n-1 {
    list-style-type: none
}

.lst-kix_2gh2eqf8mlmd-6>li:before {
    content: ""counter(lst-ctn-kix_2gh2eqf8mlmd-6, decimal) ". "
}

.lst-kix_2gh2eqf8mlmd-7>li:before {
    content: ""counter(lst-ctn-kix_2gh2eqf8mlmd-7, lower-latin) ". "
}

ul.lst-kix_s0j8qbu98r9n-6 {
    list-style-type: none
}

.lst-kix_38l448z239x5-8>li {
    counter-increment: lst-ctn-kix_38l448z239x5-8
}

ul.lst-kix_s0j8qbu98r9n-7 {
    list-style-type: none
}

ul.lst-kix_s0j8qbu98r9n-8 {
    list-style-type: none
}

ul.lst-kix_s0j8qbu98r9n-2 {
    list-style-type: none
}

ul.lst-kix_s0j8qbu98r9n-3 {
    list-style-type: none
}

ol.lst-kix_2gh2eqf8mlmd-0.start {
    counter-reset: lst-ctn-kix_2gh2eqf8mlmd-0 0
}

ul.lst-kix_s0j8qbu98r9n-4 {
    list-style-type: none
}

ol.lst-kix_38l448z239x5-0.start {
    counter-reset: lst-ctn-kix_38l448z239x5-0 0
}

ul.lst-kix_s0j8qbu98r9n-5 {
    list-style-type: none
}

.lst-kix_s0j8qbu98r9n-1>li:before {
    content: "\0025cb  "
}

.lst-kix_38l448z239x5-2>li {
    counter-increment: lst-ctn-kix_38l448z239x5-2
}

.lst-kix_s0j8qbu98r9n-2>li:before {
    content: "\0025a0  "
}

ol.lst-kix_2gh2eqf8mlmd-7.start {
    counter-reset: lst-ctn-kix_2gh2eqf8mlmd-7 0
}

.lst-kix_2gh2eqf8mlmd-7>li {
    counter-increment: lst-ctn-kix_2gh2eqf8mlmd-7
}

.lst-kix_s0j8qbu98r9n-0>li:before {
    content: "\0025cf  "
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

ol.lst-kix_38l448z239x5-1.start {
    counter-reset: lst-ctn-kix_38l448z239x5-1 0
}

.lst-kix_2gh2eqf8mlmd-0>li:before {
    content: ""counter(lst-ctn-kix_2gh2eqf8mlmd-0, decimal) ". "
}

.lst-kix_2gh2eqf8mlmd-2>li:before {
    content: ""counter(lst-ctn-kix_2gh2eqf8mlmd-2, lower-roman) ". "
}

.lst-kix_2gh2eqf8mlmd-1>li:before {
    content: ""counter(lst-ctn-kix_2gh2eqf8mlmd-1, lower-latin) ". "
}

.lst-kix_2gh2eqf8mlmd-5>li:before {
    content: ""counter(lst-ctn-kix_2gh2eqf8mlmd-5, lower-roman) ". "
}

.lst-kix_2gh2eqf8mlmd-4>li {
    counter-increment: lst-ctn-kix_2gh2eqf8mlmd-4
}

.lst-kix_2gh2eqf8mlmd-1>li {
    counter-increment: lst-ctn-kix_2gh2eqf8mlmd-1
}

ol.lst-kix_38l448z239x5-7.start {
    counter-reset: lst-ctn-kix_38l448z239x5-7 0
}

.lst-kix_2gh2eqf8mlmd-4>li:before {
    content: ""counter(lst-ctn-kix_2gh2eqf8mlmd-4, lower-latin) ". "
}

ol.lst-kix_2gh2eqf8mlmd-1.start {
    counter-reset: lst-ctn-kix_2gh2eqf8mlmd-1 0
}

.lst-kix_2gh2eqf8mlmd-3>li:before {
    content: ""counter(lst-ctn-kix_2gh2eqf8mlmd-3, decimal) ". "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c7 {
    margin-left: 36pt;
    padding-top: 17pt;
    padding-left: 0pt;
    padding-bottom: 17pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c2 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c10 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c8 {
    margin-left: 36pt;
    padding-top: 13pt;
    padding-bottom: 13pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c3 {
    padding-top: 13pt;
    text-indent: 36pt;
    padding-bottom: 13pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c6 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}

.c1 {
    padding-top: 12pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c16 {
    padding-top: 17pt;
    padding-bottom: 17pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c18 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
    text-decoration-skip-ink: none
}

.c19 {
    background-color: #ffffff;
    max-width: 100%;
    padding: 3%;
}

.c17 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 700
}

.c4 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 400
}

.c11 {
    color: inherit;
    text-decoration: inherit
}

.c15 {
    padding: 0;
    margin: 0
}

.c12 {
    margin-left: 36pt
}

.c13 {
    padding-left: 0pt
}


.c14 {
    text-indent: 36pt
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

.c19 h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}