.furtherOuter{
  height: 100%;
  background-color: #ffd6c2;
}

.furtherInner{
   height: 100%;
}

.furtherSection:nth-child(odd){
  height: 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
}

.furtherSection:nth-child(even){
  height: 100%;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
}

.furtherLeft{
  height: 100%;
  /* border: 2px solid red; */
}

.furtherRight{
  height: 100%;
  /* border: 2px solid red */
}

.furtherh1{
  text-align: left;
  font-size: 4vw;
  padding-left: 10%;
}

.furtherh1Left{
  text-align: right;
  font-size: 4vw;
  padding-right: 10%;
}

.furtherp{
  text-align: left;
  font-size: 1.5vw;
  padding-left: 10%;
}

.furtherpLeft{
  text-align: right;
  font-size: 1.5vw;
  padding-right: 10%;
}

.furtherInnerLeftColor{
  overflow-y: hidden;
  border-left: 5px solid #ff653f;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 0 3% 0 3%;
}

.furtherInnerRightColor{
  overflow-y: hidden;
  border-right: 5px solid #ffb63f;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 0 3% 0 3%;
}

.furtherStrippedColor{
  background-color: #FFD085;
}

.furtherInnerTitle{
  color: black;
  font-size: 3vh;
}

.furtherInnerTitleLeft{
  text-align: right;
  color: black;
  font-size: 3vh;
}

.furtherInnerDesc{
  margin-left: 10%;
}

.furtherInnerDescLeft{
  margin-right: 10%;
}

.furtherDescText{
  font-size: 2vh;
}

.furtherDescTextLeft{
  text-align: right;
  font-size: 2vh;
}

.furtherImageCenter{
  height: 100%;
  display: grid;
  place-items: center;
  overflow-y: hidden;
}

.furtherSize{
  height: 30vw;
}

.donateOuter{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 10%;
}

.donateText{
  font-size: 1vw;
  color: #ff653f;
}

.donateInner{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.donateAmountBtn{
  background: transparent;
  border: 5px solid black;
  font-size: 1.2vw;
  width: 100%;
}

.donateAmountBtn:hover{
  cursor: pointer;
}

.donateButtonDivs{
  padding: 4%;
}

.donateInput{
  background: transparent;
  border: 5px solid black;
  width: 90%;
  height: 2.8vh;
  outline: none;
  font-size: 1.2vw;
}

.donateInputDiv{
  padding-top: 1.5%;
  padding-left: 5%;
}

.donatePaymentDiv{
  display: grid;
}

.donatePaymentBtn{
  background-color: #ffb63f;
  border: none;
  width: 100%;
  padding: 5%;
  font-size: 0.7vw;
}

.donatePaymentBtnDiv{
  padding-bottom: 5%;
}

.donatePaymentBtn:hover{
  cursor: pointer;
}

@media (max-width:1000px){
  .furtherh1{
    font-size: 5vh;
  }

  
  .furtherh1Left{
    font-size: 5vh;
  }


  .furtherInnerTitle{
    color: black;
    font-size: 3vh;
  }


  .furtherp{
    font-size: 2.5vh;
  }

  .furtherpLeft{
    font-size: 2.5vh;
  }
  

  .furtherSection:nth-child(odd){
    grid-template-columns: 1fr;
  }
  
  .furtherSection:nth-child(even){
    grid-template-columns: 1fr;
  }

  .furtherSize{
    height: 35vh;
  }

  .donateOuter{
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 10%;
  }

  .donateText{
    font-size: 2vh;
  }

  .donateAmountBtn{
    font-size: 2vh;
  }

  .donateInput{
    font-size: 2vh;
  }

  .donatePaymentBtn{
    font-size: 2vh;
  }
}