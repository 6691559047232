.statusBackground {
	background-color: #ffe3b8;
}

.statusContent {
	height: 30vh;
}

.statusContent {
	margin: 300px auto;
}

.statusContent img {
	width: 10vmin;
	border-radius: 50%;
	padding: 3%;
}

.imageCompletedStatus {
	border: 3px solid #28e221;
}

.statusContent li:nth-child(1) .imageCompletedStatus {
	background: linear-gradient(to left, #ff653f, #ff653f, #69eb65, #69eb65);
	background-size: 400% 100%;
	animation: slideIn 0.5s 0.5s ease-out forwards;
	background-position: 100%;
}

.statusContent li:nth-child(2) .imageCompletedStatus {
	background: linear-gradient(to left, #ff653f, #ff653f, #69eb65, #69eb65);
	background-size: 400% 100%;
	animation: slideIn 0.5s 1s ease-out forwards;
	background-position: 100%;
}

.statusContent li:nth-child(3) .imageCompletedStatus {
	background: linear-gradient(to left, #ff653f, #ff653f, #69eb65, #69eb65);
	background-size: 400% 100%;
	animation: slideIn 0.5s 1.5s ease-out forwards;
	background-position: 100%;
}

.imageUncompletedStatus {
	border: 3px solid #e65936;
	background-color: #ff653f;
}

.statusContent ul {
	text-align: center;
}

.statusContent ul li {
	display: inline-block;
	width: 30vw;
}

.stepFonts {
	padding: 3%;
	font-size: 2vmin;
	font-weight: 800;
	color: #e65936;
}

.statusContent li:nth-child(1) .statusGreenText {
	color: transparent;
	background: linear-gradient(to left, #e65936, #e65936, #28e221, #28e221);
	background-clip: text;
	background-size: 400% 100%;
	animation: slideIn 0.5s 0.5s ease-out forwards;
	background-position: 100%;
}

.statusContent li:nth-child(2) .statusGreenText {
	color: transparent;
	background: linear-gradient(to left, #e65936, #e65936, #28e221, #28e221);
	background-clip: text;
	background-size: 400% 100%;
	animation: slideIn 0.5s 1s ease-out forwards;
	background-position: 100%;
}

.statusContent li:nth-child(3) .statusGreenText {
	color: transparent;
	background: linear-gradient(to left, #e65936, #e65936, #28e221, #28e221);
	background-clip: text;
	background-size: 400% 100%;
	animation: slideIn 0.5s 1.5s ease-out forwards;
	background-position: 100%;
}

.statusContent li::after {
	content: "";
	background-color: #e65936;
	height: 5px;
	width: 15%;
	position: absolute;
	margin-left: 7%;
	margin-top: -7%;
}

.statusContent li:nth-child(1)::after {
	background: linear-gradient(to left, #e65936, #e65936, #28e221);
	background-size: 200% 100%;
	animation: slideIn 0.3s 0.75s ease-out forwards;
	background-position: 100%;
}

.statusContent li:nth-child(3)::after {
	display: none;
}

@keyframes slideIn {
	0% {
		background-position: 100%;
	}

	100% {
		background-position: 0%;
	}
}

@media (max-width: 1000px) {
	.statusContent {
		display: flex;
		justify-content: center;
		height: 100%;
		margin: 100px auto;
	}

	.statusContent ul {
		padding-left: 5px;
	}

	.statusContent li::after {
		content: "";
		background-color: #e65936;
		height: 20%;
		width: 5px;
		position: absolute;
		margin-left: 0%;
		margin-top: 0%;
	}

	.statusContent li:nth-child(1)::after {
		background-image: linear-gradient(to bottom, #28e221, #e65936);
	}

	.statusContent ul li {
		display: block;
		padding-bottom: 90%;
		width: 30vw;
	}

	.statusContent img {
		width: 25vmin;
	}

	.stepFonts {
		font-size: 5vmin;
	}
}
