.whiteBtn{
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background-color: white;
    color: black;
    text-decoration: none;
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
    outline: none;
    width: 315px;
    height: 56px;
    border: none;
    border-radius: 28px;
}
.whiteBtn:hover{
    box-shadow: 5px 5px 1px rgba(41, 40, 40, 0.5);
    background-color: white;
    color:#7289da;
    transition: all 0.3s ease-in-out;
}