.LoginLogo{
  height: 20vh;
}

.LogoTextLeft{
  display: flex;
}

.ImageTextLeft{
  height: 35vmin;
}


.ImageDivLeft{
  position: relative;
  top: 25%;
  left: 5%;
}

.innerWrapRight{
  text-align: center;
  font-size: 4vmin;
}

.loginoptions{
  display: grid;
  place-items: center;

}

.bottomForm{
  display: flex;
  width: 100%;
}

.checkBoxForm{
  display: grid;
  place-items: center;
}

.proceedButtonContainer{
  margin-top: 5%;
  padding-bottom: 5%;
}

.submitButtonContainer{
  display: grid;
  place-items: center;
}

#warningMessage{
  font-size: 2.5vmin;
  color: red;
  text-align: center;
}

.forgotPassword{
  font-size: 1.2vw;
  color: #ff653f;
  text-decoration: none;
}

.underlineOrange {
  display: inline-block;
}

.underlineOrange::after {
  content: '';
  width: 0px;
  height: 2px;
  display: block;
  background: #ff653f;
  transition: 300ms;
}

.underlineOrange:hover::after {
  width: 100%;
}


@media (max-width:1200px){


}

@media (max-width:1000px){
  .LoginLogo{
    height: 25vmin
  }

  .LogoTextLeft h1{
    visibility: hidden;
  }

  .LogoTextLeft{
    display: grid;
    place-items: center;
  }

  .ImageTextLeft{
    height: 25vw;
  }

  .ImageDivLeft{
    visibility: hidden;
  }

  .OuterWrapper{
    grid-template-columns: 0.3fr 1fr;
  }

  .topForm{
    display: block;
  }

  .titleTOS{
    display: block;
    margin-left: 20%;
  }
  
  .forgotPassword{
    font-size: 4vw;
  }
}
