.blueBtn{
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background-color: #7289DA;
    color: white;
    text-decoration: none;
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
    outline: none;
    width: 315px;
    height: 56px;
    border: none;
    border-radius: 15px;
}
.blueBtn:hover{
    box-shadow: 5px 5px 1px rgba(41, 40, 40, 0.5);
    background-color: #90a4eb;
    color:white;
    transition: all 0.3s ease-in-out;
}