.App {
  text-align: center;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

br{
  user-select: none;
}

.move{
  margin-top: 20%;
}
