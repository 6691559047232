*{
  scroll-behavior: smooth;
}

html{
  margin: 0;
  background-color: #EEEEEE;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* width */
::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(187, 187, 187); 
  border-radius: 10px;
}
 

/* Handle */
::-webkit-scrollbar-thumb {
  background:#ffb63f; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: 	#ffce83; 
}

*::selection{
  background-color: #ffdeab;
  color: white;
}
