.tasks{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: 100%;
  min-height: 80vh;
  height: 100%;
}

.taskCard{
  width: 300px;
  height: 500px;
  font-size: 1.5rem;
  text-align: center;
  transition: all ease 0.5s;

  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;

}    

.taskCard:hover{
  width: 310px;
  height: 510px;
  cursor: pointer;
  background: white;
  border-top: 3px solid orange;
  border-bottom: 3px solid orange;
  width: 320px;
  height: 520px;
  cursor: pointer;
}
.taskCard img{
  width: 15rem;
}

.chooseRoleDiv{
  width: 300px;
  height: 500px;
  font-size: 1.5rem;
  text-align: center;
  transition: all ease 0.5s;

  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
}

.chooseRoleDiv:hover{
  width: 310px;
  height: 510px;
  cursor: pointer;
  background: white;
  border-top: 3px solid orange;
  border-bottom: 3px solid orange;
  width: 320px;
  height: 520px;
  cursor: pointer;
}

.chooseRoleDiv img{
  width: 15rem;
}


@media (max-width: 1300px) {
  .tasks{
    height: 100%;
  }
}