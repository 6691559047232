.loadingCover{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all ease 2s;
}

.dot-load{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.dot-load div{
    position: absolute;
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: jump 1.3s ease-in-out infinite;
    margin-left: -5rem;
}


.dot-load div:nth-child(2){
    margin-left: 0rem;
    animation-delay: -1.1s;
}
.dot-load div:nth-child(3){
    margin-left: 5rem;
    animation-delay: -0.9s;
}

.loadtext{
    margin-top: 3rem;
    font-family: arbotek, sans-serif;
    color: white;
    font-size: 2rem;
}

.stopLoading{
    transform: translateY(-200%);
}

@keyframes jump {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-3rem);
	}
}