.oauthback{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.oauthback h1 {
  font-size: 4vw;
}