.taskshome {
	/* border: solid 2px red; */
	display: flex;
	flex-direction: row;
	min-height: 80vh;
}

.displaytasks {
	height: 100%;
	width: 30%;
	min-width: 150px;
	max-height: 100vh;
	/* border: solid 2px green; */
	border-right: 2px solid orange;
}

.taskName {
	text-align: left;
	padding: 1rem;
	font-size: 1rem;
	margin-bottom: 5px;
	transition: all ease 0.5s;
}

.taskName:hover {
	background-color: rgb(221, 221, 221);
	cursor: pointer;
}

.selectedTask {
	background-color: white;
	border-left: solid 5px orange;
}

.dotasks {
	height: 100%;
	width: 70%;
	/* border: solid 2px blue; */
}

.emptyTaskFont {
	font-size: 3rem;
	text-align: center;
	font-family: "Open Sans";
}

.currentTaskDisplayed {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.inputtask {
	font-size: 1.5rem;
	transition: all ease 0.5;
}

.inputtask:hover {
	border: solid 1px rgb(255, 208, 0);
}

.inputtask:focus {
	border: solid 1px orange;
	outline: none !important;
}

#log {
	color: red;
}

#success {
	color: rgb(10, 149, 10);
}

.notDone {
	color: orange;
}

.done {
	color: rgb(9, 163, 9);
}

.displaystats {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	text-align: center;
}

.hoursDisplayed {
	color: rgb(9, 163, 9);
}
