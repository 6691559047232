.volunteerHome {
	/* border: solid 2px red; */
	display: flex;
	flex-direction: row;
	min-height: 80vh;
}

.displayVolunteer {
	height: 100%;
	width: 30%;
	min-width: 150px;
	max-height: 100vh;
	border-right: solid 2px orange;
}

.currentVolunteer {
	height: 100%;
	width: 70%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 5rem;
}

.volunteers {
	text-align: left;
	padding: 1rem;
	font-size: 1rem;
	margin-bottom: 5px;
	transition: all ease 0.5s;
}

.volunteers:hover {
	background-color: rgb(221, 221, 221);
	cursor: pointer;
}

.selectedVolunteers {
	background-color: white;
	border-left: solid 5px orange;
}

.centerShowVolunteer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.showCurrentVolunteer {
	display: flex;
	justify-content: center;
	align-items: left;
	flex-direction: column;
}

.showCurrentVolunteer label {
	font-weight: 500;
}

.actionButton {
	margin-top: 3rem;
	padding: 1rem;
	border-radius: 10px;
	outline: none;
	border: none;
	transition: all ease 0.3s;
}

.actionButton:hover {
	padding: 1.2rem;
	cursor: pointer;
}

.subemail {
	font-size: 0.8rem;
}

.volunteerEmptyFont {
	font-size: 3rem;
	font-family: "Open Sans";
}

.volunteerAction {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.editVolunteerHours {
	outline: none;
	border: none;
	background-color: transparent;
	border-bottom: 2px solid black;
	font-size: larger;
	width: 3rem;
}

.editVolunteerHoursBox {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
}

.addVolunteerHours {
	margin-top: 2rem;
	padding: 1rem;
	border-radius: 10px;
	outline: none;
	border: none;
	transition: all ease 0.3s;
	background-color: rgb(31, 192, 31);
	color: white;
}

.addVolunteerHours:hover {
	padding: 1.2rem;
	cursor: pointer;
}
