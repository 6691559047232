.volunteerHome{
    /* border: solid 2px red; */
    display: flex;
    flex-direction: row;
}

.displayReviews{
    height: 100%;
    width: 30%;
    min-width: 150px;
    max-height: 100vh;
    border-right: solid 2px orange;
}

.currentReview{
    height: 100%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
}

.reviews{
    text-align: left;
    padding: 1rem;
    font-size: 1rem;
    margin-bottom: 5px;
    transition: all ease 0.5s;
}

.reviews:hover{
    background-color: rgb(221, 221, 221);
    cursor: pointer;
}

.selectedReviews{
    background-color: white;
    border-left: solid 5px orange;
}

.centerShowVolunteer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.showCurrentVolunteer{
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
}

.showCurrentVolunteer label{
    font-weight: 500;
}

.actionButtonReview{
    margin-top: 3rem;
    padding: 1rem;
    border-radius: 10px;
    outline: none;
    border: none;
    transition: all ease 0.3s;
}

.actionButton:hover{
    padding: 1.2rem;
    cursor: pointer;
}

.subname{
    font-size: 0.8rem;
}

.volunteerEmptyFont{
    font-size: 3rem;
}

.reviewDisplayed label{
    font-weight: 500;
}

.reviewDisplayed{
    padding: 0 1rem 0 1rem;
    word-wrap: break-word;
}

.actionButtonDiv{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}

.displaystatsReviews{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
}