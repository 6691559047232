.Home {
    text-align: center;
    height: 100%;
    margin: 0;
}
  
.move{
  margin-top: 20%;
}

.floating-arrow-mobile{
  margin-top: -5rem;
}

