.logoutBackground{
  height: 90vh;
  background-color: #fff0e9;
  display: grid;
  place-items: center;
}

.logoutInnerBackground{
  text-align: center;
}

.logoutInnerBackground h2{
  text-align: center;
}