.redeemBackground {
  background-color: #ffe3b8;
}

#certificate{
  width: 50vw;
}

#redeemBtnDiv div{
  padding: .5rem;
}

.certificateDownloadContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.certificateDownloadContainer div{
  padding: 1rem;
}

.displaycertificate{
  display: flex;
  align-items: center;
  justify-content: center;
}

.redeemContent {
  min-height: 80vh;
  height: 100%;
}

.redeemContent h2 {
  text-align: center;
  font-size: 3rem;
}

#redeemDiv {
  width: 25vw;
  margin: auto;
}

#redeemBtnDiv {
  overflow-y: hidden;
}

#redeemBtnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#incrementInputBtns {
  overflow-y: hidden;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  width: 300px;
}
