.floatingarrow img{
    transition: 0.5s ease-in-out;
    width:6rem;
}
.floatingarrow{
    animation: floatingarrow 2s ease-in-out infinite;
    z-index: 1000;
}

.floatingarrow:hover{
    cursor: pointer;
}

.floatingarrow:hover > img{
    width: 7rem;
}

@keyframes floatingarrow{
    0% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(50%);
    }
    100%{
        transform: translateY(0%);
    }
}