.HomeDiv{
    background-color: #EEEEEE;
}

.InnerHomeDiv{
    display: grid;
    /* justify-content: space-around; */
    grid-template-columns: 1fr 1fr;
    height: 100%;
    overflow-y: hidden;
}

.HomeLSection{

    /* display: grid;
    place-items: center; */
    /* margin-left: -10%; */
    /* display: flex;
    justify-content: center; */
    height: 50rem;

}   

.collectiveCircles{
    /* animation: spin 1s linear infinite; */
    position: relative;
    /* width: 50vw; */
    height: 100%;
    overflow-y: hidden;
    display: grid;
    place-content: center;
}

.centerCollective{
    position: relative;
    overflow-y: hidden;
    height: 100%;
}

.HomeRSection{
    /* text-align: left; */
    overflow-y: hidden; 
    display: table;
    place-items: center;
    /* margin-left: 10%; */
    z-index: 1;
}

.HomeRSection h1{
    margin-bottom: -5%;
    font-size: 6vmin;

    font-family: 'Nunito', sans-serif;
}

.HomeRSection h3{
    margin-top: 10%;
    font-size: 3vmin;
    font-weight: 100;

    font-family: 'Roboto', sans-serif;
}


.HomeRSection h1:nth-child(2){
    color: #F0A500;
}

.HomeRDescription{
    position: relative;
    overflow-y: hidden;
}

.HomeRDescription h1{
    overflow-y: hidden;
}

.centerButton{
    display: flex;
    flex-direction: row;
    overflow: visible;
    justify-content: space-around;
    flex-wrap: wrap;
}

.centerButton > *{
    flex-grow: 1;
}

.HomeCircle:nth-child(1){
    height: 30vw;
    width: 30vw;
    background-color: #fd977c;
    border-radius: 50%;
    opacity: 0.58;

    margin-left: 20%;
    
    animation: homeC2 4s linear infinite;

}


.HomeCircle:nth-child(2){
    height: 15vw;
    width: 15vw;
    background-color: #ff653f;
    border-radius: 50%;
    opacity: 0.59;

    margin-top: -80%;
    margin-left: 5%;

    animation: homeC1 5s linear infinite;

}

.HomeCircle:nth-child(3){
    height: 10vw;
    width: 10vw;
    background-color: #ffb63f;
    border-radius: 50%;
    opacity: 0.53;

    margin-top: -45%;
    margin-left: 10%;

    overflow: hidden;
    

    animation: homeC3 6s linear infinite;
}

.HomeCircle:nth-child(4){
    height: 30px;
    width:  30px;

    border-radius: 50%;
    background-color: #ffb63f;
    opacity: 0.53;

    position: relative;
    top: -100%;
    left: 90%;

    animation: orbitHover 3s linear infinite;
    display: none;
}

.HomeCircle:nth-child(5){
    height: 35px;
    width:  35px;

    border-radius: 50%;
    background-color: #ff653f;
    opacity: 0.53;

    position: relative;
    top: -250%;

    animation: orbitHover 2.5s linear infinite;
    display: none;
}

.HomeCircle:nth-child(6){
    height: 30px;
    width:  30px;

    border-radius: 50%;
    background-color: #ff653f;
    opacity: 0.53;

    margin-top: -80%;
    margin-left: 100%;

    animation: orbitHover 3.5s linear infinite;
    display: none;
}

.Homecursor1{
    display: inline-block;
    width: 3px;
    font-size: 4vh;
    background-color: #ff653f;
    margin-left: 0.3rem;
    animation: blink1 1s infinite;
    visibility: visible;
    overflow: hidden;
}

.Homecursor2{
    display: inline-block;
    width: 2px;
    font-size: 3vh;
    background-color: black;
    margin-left: 0.3rem;
    animation: blink2 1s infinite;
    visibility: hidden;
    overflow: hidden;
}

.Homecursor3{
    display: inline-block;
    width: 2px;
    font-size: 3vh;
    background-color: black;
    margin-left: 0.3rem;
    animation: blink2 1s infinite;
    visibility: hidden;
    overflow: hidden;
}

.cursor.typing{
    animation: none;
}

.typed-text{
    font-weight: normal;
    color: #ff653f;
}

.typed-text2{
    width: 50%;
}


@keyframes blink1{
    0% {background-color:#ff653f;}
    49% {background-color:#ff653f;}
    50% {background-color: transparent;}
    99% {background-color: transparent;}
    100% {background-color: #ff653f;}
}

@keyframes blink2{
    0% {background-color:black;}
    49% {background-color:black;}
    50% {background-color: transparent;}
    99% {background-color: transparent;}
    100% {background-color: black;}
}

@media (max-width:1400px) {
    .HomeRSection h1:nth-child(2) {
        color: #573D00;
    }
    
    .HomeCircle:nth-child(1){
        height: 40vw;
        width: 40vw;
    }
    
    .HomeCircle:nth-child(2){
        height: 30vw;
        width: 30vw;

        margin-top: -90%;
        margin-left: 5%;
    }
    
    .HomeCircle:nth-child(3){
        height: 18vw;
        width: 18vw;

        margin-top: -40%;
        margin-left: 10%;
    }

    .centerButton{
        display: grid;
        place-items: left;
        margin-left: 0;
    }

    .InnerHomeDiv{
        display: inline-block;
        place-content: center;
    }

    .HomeLSection{
        position: absolute;
        width: 100vw;
        height: 100%;
        left: 0%;
        /* display: flex;
        justify-content: center; */
    }

    
    .centerCollective{
        position: relative;
        overflow-y: hidden;
    }

    .collectiveCircles{
        top: 0rem;
        width: 90vw;
        height: 90vh;
        display: grid;
        place-content: center;
    }
    

}


@media (max-width:800px) {
    .HomeLSection{
        top: -2vh;
    }

    .HomeCircle:nth-child(1){
        height: 60vw;
        width: 60vw;
    }
    
    .HomeCircle:nth-child(2){
        height: 50vw;
        width: 50vw;

        margin-top: -100%;
        margin-left: 0;
    }
    
    .HomeCircle:nth-child(3){
        height: 30vw;
        width: 30vw;

        margin-left: -10;
    }

    .HomeCircle:nth-child(4){
        display: block;
    }

    .HomeCircle:nth-child(5){
        display: block;
    }

    .HomeCircle:nth-child(6){
        display: block;
    }
}

@media (max-width:600px) {


    .HomeRSection h1{
        font-size: 6vmin;
    }

    .HomeRSection h3{
        font-size: 3vmin;
    }
    .centerButton{
        display: grid;
        place-items: left;
        margin-left: 0;
        position: relative;
        top: 10%;
    }
    
}




@keyframes homeC1{


    50%{
        transform: scale(0.7, 0.7);
        transform: translateY(10%)
    }


}

@keyframes homeC2{


    50%{
        /* transform: scale(0.8, 0.8); */
        transform: translateY(10%)
    }


}

@keyframes homeC3{

    0% {
        /* transform: scale(1.02, 1.02) */
    }

    50%{
        /* transform: scale(0.5, 0.5); */
        transform: translateY(10%)
    }

    100%{
        /* transform: scale(1.02, 1.02) */
    }

}

@keyframes orbitHover{
    0%{
        transform: translate(0%, 0%);
    }
    50%{
        transform: translateY(20%);
        transform: scale(0.5,0.5)
    }
    100%{
        transform: translate(0%, 0%);
    }
}


@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}