*{
    overflow-x: hidden;
}

.content-div {
    height: 1080px;
    font-family: 'Antonio', sans-serif;
    color: white;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
}

.button-container {
    display: grid;
    grid-template-columns: 0.3fr 0.3fr;
    margin-left: 29%;
}

.textBorder {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.homeDiv {
    color: white;
}


.buttonClass {
    transition: all ease 0.5s;
    border-radius: 50px;
    outline: none;
    text-decoration: none;
    border: none;
    background-color: #003887;
    color: white;
    font-size: 16px;
}

.buttonClass:hover {
    transition: all ease 0.5s;
    background-color: #134c9c;
    cursor: pointer;
    color: rgb(255, 255, 255);
}

