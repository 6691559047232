.ourteam {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100vh;
}

.ourteam:hover .teamMember:not(:hover) {
  opacity: 0.5;
  transform: scale(0.9);
}

.teamMemberPic {
  width: 300px;
  height: 300px;
}

.teamMember {
  transition-duration: 0.5s;
  cursor: pointer;
  perspective: 1000px;
  width: 300px;
  position:relative;
  border: #ffb83e solid 3px;
  border-radius: 4px;
  transition-duration: 1.2s;
}

.teamMember:hover {
  opacity: 1;
  transform: scale(1);
  transform: rotateY(0.5turn);
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.teamMember div p {
  display: none
}
.teamMember div h2 {
  display: none
}
.teamMember:hover{
  height: 300px;
}
.teamMember:hover div img {
  display: none
}

.teamMember:hover div p {
  text-align: center;
  display: flex;
  max-width: 300px;
  transform: rotateY(180deg);
  color: #f6653d ;
}
.teamMember:hover div h2 {
  text-align: center;
display: block;
  max-width: 300px;
  transform: rotateY(180deg);
  color: #f6653d ;
}
h2{
  text-align: center;
}
@media (min-width: 800px) {
  .displayMembers:hover .teammember:not(:hover) {
      opacity: 0.5;
      transform: scale(0.9);
      transition-duration: 0.5s;
  }
  .teammember:hover {
      transform: scale(1);
      transition-duration: 0.5s;
  }
  .teammember:hover .memberinfo {
      border-bottom: 5px solid rgb(255, 231, 92);
      background-color: lightyellow;
      transition-duration: 0.5s;
  }
  .teammember:nth-child(even):hover .memberinfo {
      border-top: 5px solid rgb(255, 231, 92);
      border-bottom: none;
      transition-duration: 0.5s;
  }
  .teammember:hover .memberinfo .memberinfo-text {
      font-size: 200%;
      transition-duration: 0.5s;
  }
}

@media (max-width: 800px) {
  .displayMembers {
      display: grid;
      grid-template-columns: 1fr;
      height: 100%;
  }
  .memberpic img {
      height: 300px;
      width: 300px;
      margin-top: 30px;
  }
  .memberpic {
      background-color: white;
  }
  .teammember:nth-child(even) {
      flex-direction: column;
  }
  .teammember:nth-child(even):hover .memberinfo {
      border-top: none;
      border-bottom: 5px solid rgb(255, 231, 92);
  }
  .displayMembers:hover .teammember:not(:hover) {
      opacity: 1;
      transform: scale(1);
  }
}

