.OuterWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
}

.innerWrapLeft {
  background-color: #ffdeab;
}

.centerWrap {
  background-color: #eeeeee;
  width: 50%;
}

.SignLogo {
  height: 20vh;
}

.LogoTextLeft {
  display: flex;
}

.ImageTextLeft {
  height: 35vmin;
}

.otsitivity {
  margin-top: 4rem;
  margin-left: -3rem;
  color: #878787;
  font-size: 3vw;
  font-weight: 100;
}

.ImageDivLeft {
  position: relative;
  top: 25%;
  left: 5%;
}

.signLogTitleText {
  font-size: 2.5vw;
  text-align: center;
  font-family: "Ubuntu", sans-serif;
}

.topForm {
  display: flex;
  width: 100%;
}
.bottomForm {
  display: flex;
  width: 100%;
}

.checkBoxForm {
  padding-top: 3rem;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.proceedButtonContainer {
  margin-top: 5%;
  padding-bottom: 5%;
  display: flex;
  justify-content: center;
}

.submitButtonContainer {
  display: grid;
  place-items: center;
}

#warningMessage {
  font-size: 2.5vmin;
  color: red;
  text-align: center;
}

#warningMessage2 {
  text-align: center;
  color: red;
  padding: 1rem;
}

.SignUpOuter {
}

.TOS {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
}

.TOSContent {
  display: flex;
  height: 30vh;
  width: 50vw;
  border: 2px solid rgb(184, 184, 184);
  transition: 0.2s all ease-in-out;
  border-radius: 5px;
}

.TOSContent:hover{
  border: 2px solid rgb(68, 68, 68);
}

.titleTOS {
  text-align: center;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.titleTOS h3 {
  font-size: 40px;
}

.closeTOS {
  position: fixed;
  font-size: larger;
  top: 3rem;
  right: 3rem;
  font-size: 2rem;
}

.closeTOS:hover {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .TOS {
  }
}

@media (max-width: 1000px) {
  .SignLogo {
    height: 25vmin;
  }

  .otsitivity {
    visibility: hidden;
  }

  .LogoTextLeft {
    display: grid;
    place-items: center;
  }

  .ImageTextLeft {
    height: 25vw;
  }

  .ImageDivLeft {
    visibility: hidden;
  }

  .OuterWrapper {
    grid-template-columns: 0.3fr 1fr;
  }

  .topForm {
    display: block;
  }

  .titleTOS {
    display: block;
    margin-left: 20%;
  }

  @media (max-width: 800px) {
    .signLogTitleText {
      font-size: 6vw;
    }
  }
}
